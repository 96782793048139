import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/amuller/github-tkd/website-carbon/website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Our timezone matches your timezone. We can work in West coast time, East coast time, and anything in-between.`}</p>
      <p>{`We are proud of the work we do:`}</p>
    </PageDescription>
    <Row className="meetups-list" mdxType="Row">
      <Column colSm={2} colMd={3} colLg={3} mdxType="Column">
        <Row className="image-card-group" mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard href="https://triunity.info/" mdxType="ImageCard">
              <span {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "600px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "100%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABf0lEQVQ4y+2Uy0sCURTG/XNb1T/QpkWb2oSaIAU9N5VoiWLkpAVShI8SXw2a6KipQyqa+Zg7d76Yqw6jjdHCRQs/OMw9Z4Yf5zv3MCYsWKYlcDFARVF+xKSuf6+Xms7WGNCoqNYopSxGZwV0Bs7qBo1oljvdHjqfXfSH0lw71KBTvRPN8r47iFXLObacD1jZPkCx9oFkvoxwJs+6e6uIKIuNKUjza4hsvYMhkac6Z0AunMDGsRc2XxSbh5cgMoU7FMOuk8NZ4AlWxw3s1xH4MyI8L2VchAW4YiXs3efgipbgjVdQafWmb9nm5LC2c4IYX2B5IJqG/SqII18InlAU66cBmAM5WG952O+yMHM8LBwPf6oKR0TAa7U9AqqWVAn1Bh6TWXYRqoYSQX8gsVxstRHPlZAqVJHIVyDUm0gV3sELNaQFEc/F5niOY8tUN1SjFVGhEiGQZXn0pBSEEBYSkTHQzVFbGxUqj9dkdj3+osl3pnkAo/NvoXW4/Nv8P+A34sAUMZBY8/QAAAAASUVORK5CYII=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/54444e54282de88907965e8c5b7c976e/b5e35/triunity_logo_600.webp 288w", "/static/54444e54282de88907965e8c5b7c976e/0bf43/triunity_logo_600.webp 576w", "/static/54444e54282de88907965e8c5b7c976e/504ac/triunity_logo_600.webp 600w"],
                    "sizes": "(max-width: 600px) 100vw, 600px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/54444e54282de88907965e8c5b7c976e/0b458/triunity_logo_600.png 288w", "/static/54444e54282de88907965e8c5b7c976e/91cfa/triunity_logo_600.png 576w", "/static/54444e54282de88907965e8c5b7c976e/8dd8b/triunity_logo_600.png 600w"],
                    "sizes": "(max-width: 600px) 100vw, 600px",
                    "type": "image/png"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/54444e54282de88907965e8c5b7c976e/8dd8b/triunity_logo_600.png",
                    "alt": "Triunity Inc.",
                    "title": "Triunity Inc.",
                    "loading": "lazy",
                    "decoding": "async",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span>
  </ImageCard>
  </Column>
        </Row>
      </Column>
      <Column colLg={6} colMd={4} mdxType="Column">
        <h2>
  Triunity Inc.
  <span>Digital Transformation and Technology Commercialization Consulting Firm</span>
        </h2>
        <p>{`Over 30 years of engagement and employment experience in public, private, and entrepreneurial ventures`}</p>
        <ul>
          <li parentName="ul">{`Financial Services – eBusiness Development, B2B Marketing`}</li>
          <li parentName="ul">{`Capital Markets – Forex Trading`}</li>
          <li parentName="ul">{`Public Sector – State & Local, Public Health`}</li>
          <li parentName="ul">{`Business Intelligence and Data Management`}</li>
          <li parentName="ul">{`Healthcare Technology and Advisory`}</li>
          <li parentName="ul">{`Digital Transformation Management`}</li>
          <li parentName="ul">{`Human Capital Management`}</li>
          <li parentName="ul">{`eLearning Technology and Service Delivery`}</li>
        </ul>
      </Column>
      <Column colMd={12} colLg={12} mdxType="Column">
        <br /><br />
      </Column>
      <Column colLg={10} mdxType="Column">
        <p><strong parentName="p"><em parentName="strong">{`The TekDatum Team is my go-to partner for engagements requiring a thorough understanding and application of data science.  They can quickly bring together diverse teams of experienced professionals to address the complexities and challenges of building highly scalable assets that increase business value and data intelligence`}</em></strong>{`.`}</p>
        <cite>– David Holly, Executive Lead<br />Triunity Ventures</cite>
      </Column>
    </Row>
    <Row className="meetups-list" mdxType="Row">
      <Column colSm={2} colMd={3} colLg={3} mdxType="Column">
        <Row className="image-card-group" mdxType="Row">
  <Column noGutterSm mdxType="Column">
    <ImageCard href="https://www.motus.com/" mdxType="ImageCard">
              <span {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "600px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "100%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACZ0lEQVQ4y52Uz2/SYBjHe/foyWiiMZ6NVy9GPZj4F3jS+EeYePLobnJgZiYumMEEpttMzOLKkLqlyBLYQHFsNI6DxGAUpNCWUmj79mv6g66shRDf5E3f932e9/P8fEshYBiGYU3v2rv3fk8PahpM96ynwScCvQq6Z91VjTH4NGggcATbbmq4yUo490FAa0Css55mBDowETiCvWuooNY6oF63ceZ9F38GBL8Vgn1eQ1XUfZ4GAokjFFQD5zcFUEke9/I9HIk6hsTAbltD6HiAJ4cK+voMIWuOcOOXCuoNj8spAYp+ckHWDTyu9HF9R0Kpo485EQwktjBaH4JK8LjzWbL2JlR1ZA/3ZVzZErDT0nzF8wFHwtxfDdRqx8pduau7cjN/FzYFnN3o4kCYwUNvkm+xEqg4j0spAXOcgqecgou0YBXpNivNVhSvxbpMcDUjWlBquW3PBI9rjIiffRLonQUMema6ky+z5+ZrAzzYk3G/0MPzmmIVxk1PwMuhJnU8cS44yfAmBpY9I7jBqbEXousghEBVVVehr9nh/ajXUf1+bBsjxJUPh8NgD0VRRDweR2w5hsVIBOxHGusNFXNcH2bLLS4tIRR6BpZl0Ww1kU6nLf0Mk3ENWMDRprBXAMMwoFMp5HI5vF1J4EaqgQORIFwHouksPm3RWF1fQ61WQ+RVBNFYFMmVpBvRGLByWEG5XEaxVMQRxyG3zeBltY27uz084giK5W/4+qWEfCGP8HwY2WwWNE1j4cUCZFn2h2yCx6fduOZPwdIwzDNbV5Ik9+v1zlcUf0+eVNY4VVEjoMq+tvnf6QX/A6vL4eBnW/R5AAAAAElFTkSuQmCC')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/994476cb3d81002b76d56e961183b4e8/b5e35/motus_white.webp 288w", "/static/994476cb3d81002b76d56e961183b4e8/0bf43/motus_white.webp 576w", "/static/994476cb3d81002b76d56e961183b4e8/504ac/motus_white.webp 600w"],
                    "sizes": "(max-width: 600px) 100vw, 600px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/994476cb3d81002b76d56e961183b4e8/0b458/motus_white.png 288w", "/static/994476cb3d81002b76d56e961183b4e8/91cfa/motus_white.png 576w", "/static/994476cb3d81002b76d56e961183b4e8/8dd8b/motus_white.png 600w"],
                    "sizes": "(max-width: 600px) 100vw, 600px",
                    "type": "image/png"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/994476cb3d81002b76d56e961183b4e8/8dd8b/motus_white.png",
                    "alt": "Motus, LCC",
                    "title": "Motus, LCC",
                    "loading": "lazy",
                    "decoding": "async",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span>
  </ImageCard>
  </Column>
        </Row>
      </Column>
      <Column colLg={7} colMd={5} mdxType="Column">
        <h2>
  Motus, LLC
  <span>Leader in vehicle reimbursement solutions</span>
        </h2>
        <p><em parentName="p">{`Motus, LLC is a leader in vehicle reimbursement solutions`}</em></p>
        <p>{`As QA members, we apply the principles of an agile team to analyze requirements and actively recommend improvement points,
reducing risks before a development and testing process begins.
This allows us to focus on testing new scenarios while balancing our efficiency. `}</p>
        <br /><br />
We organized and developed automation solutions with <strong> Playwright and Cypress </strong> <b> for e2e and API testing.</b>
      </Column>
    </Row>
    <Row className="meetups-list" mdxType="Row">
      <Column colSm={2} colMd={3} colLg={3} mdxType="Column">
        <Row className="image-card-group" mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard href="http://www.edify.cr/" mdxType="ImageCard">
              <span {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "600px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "100%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsTAAALEwEAmpwYAAADNklEQVQ4y43U+0tTYRgHcP+MICQprIRISqWwVeT07JxtOncu23HvuW5zc/cLtuMFt9yW97zMzE2zTHOoRRRtBRXVD0FXwky7QQT92g/h1JVQLPLQxUgL3h/fz8v3ed6HJwvGORnGyjAWwTlrbZMQaPX5W8tJI4QyMoyFcU6qApxdqDs+KARPcLba0kpKJDDOZckwFkKZ0kpKSRpmnr+4dfde/MIVgrVCKJDjjIJgDiu14VDzwtxo6sP80NjFAzAO45z49O/YOPP8hcXbJIGJIypuH8ztV+iLFfweqR6wZPpK9ufXI9HxhESGroudQqAY1jnsuniv6kxAOtqCnIngl7vkS5Et6UfDsXhyPfw9tkvw7y0Fg83KzMvir1M7M5dzM7cLMpeK0pHs5Qf/gQtkVI8TylzdtRgvTJ3asdCRszyev3J9e/rVaOx8YiPs8AUOKqoIElgYdY2FtFZraZ3mfLAkk8hJv4tHx65K4H9iLajhsWoKM6M4wDQTJ8ozk1sX7/8ztm81tg3+OpCT6stdcOSl/NuXTu5I2bOXbozGphISaOOGQVS/F8p4t35u3falb9uKJ++Tafeie/Pyw5FYfMOanT5/kRyEQMU7IX/OUThvLJoDhfOWwo/tm9Izw9GJ5Eb/bDvqPyTXIhirwCgFRitQWo4y5QQzS+SuJE9Hp5Prxp6df+lpCInTW4YyZSgDoYwUZRRaw5xuz5eb47Hp5IG/Y63h0dNn9x8/vXbjjoa3S1VAnH4Jogl1RN4+efD+zauBkQkJQvyJxaOmzKTBWWV0lVZSJRU6CGXK1PSRcp0M5ypoq5q2ygm+TE2Ll9dgGcbSZg8wudVUjd4hkAYHhNIqYAImj8Eh4Kz1oFyrAibkh/+FpSqAMZbugRFQ7Q52RryN4YZQZ21TS1NLd7jzpN4uBNp6zZ5G4Vj7z5hrMM5a+2Jngcntb+lBCF6qAl39Q4HW3vbeqNndgDHWoXOTBGcrqdCJ5Bcu+Y5tbT2DStIQ7OzX232u+mBjqKu5o88XaINWO1d3rF1JGtbULO4UCGXkBO9tDLvqg0ZnnS/QVu2qR3AemNwYYxE7r+HtCMFDqztLXEPfAAm7+MJDmX7+AAAAAElFTkSuQmCC')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/0f2c49350a4462007b6ec4221ac57a0b/b5e35/edify-logo-dark.webp 288w", "/static/0f2c49350a4462007b6ec4221ac57a0b/0bf43/edify-logo-dark.webp 576w", "/static/0f2c49350a4462007b6ec4221ac57a0b/504ac/edify-logo-dark.webp 600w"],
                    "sizes": "(max-width: 600px) 100vw, 600px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/0f2c49350a4462007b6ec4221ac57a0b/0b458/edify-logo-dark.png 288w", "/static/0f2c49350a4462007b6ec4221ac57a0b/91cfa/edify-logo-dark.png 576w", "/static/0f2c49350a4462007b6ec4221ac57a0b/8dd8b/edify-logo-dark.png 600w"],
                    "sizes": "(max-width: 600px) 100vw, 600px",
                    "type": "image/png"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/0f2c49350a4462007b6ec4221ac57a0b/8dd8b/edify-logo-dark.png",
                    "alt": "Edify Software Consulting",
                    "title": "Edify Software Consulting",
                    "loading": "lazy",
                    "decoding": "async",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span>
  </ImageCard>
  </Column>
        </Row>
      </Column>
      <Column colLg={7} colMd={5} mdxType="Column">
        <h2>
  Edify
  <span>Student Information Systems</span>
        </h2>
        <p>{`Edify provides the insight and expertise to build great educational software collaboratively. In addition, they have a great depth of understanding of the education sector, allowing them to implement solutions in a very directed and cost-effective way.  `}</p>
        <p>{`TekDatum team established a `}<strong parentName="p">{`high quality-standard`}</strong>{` process for planning and execution of manual, automated, and load testing.`}</p>
      </Column>
    </Row>
    {/* 
     <Row className="meetups-list">
     <Column  colSm={2} colMd={3} colLg={3}>
     <Row className="image-card-group">
      <Column colMd={4} colLg={4} noGutterSm>
        <ImageCard href="https://aquestive.com/">
     ![Aquestive](/components/images/Customers/aquestive_logo_600_2.png)
       </ImageCard>
      </Column>
     </Row>
     </Column>
     <Column colLg={7} colMd={5}>
     <h2>
      Aquestive Therapeutics
      <span>Our pipeline of medicines aims to overcome barriers that patients face with existing treatment options.</span>
     </h2>
     
     Aquestive is a pharmaceutical company aiming to solve patients' problems by advancing current standards of care through simplifying complex delivery methods. 
     Aquestive is developing orally administered products to provide novel alternatives to invasive and inconvenient standard-of-care therapies.
     
     </Column>
     </Row>
     */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      